import './App.css';

function App() {
    return (
        <div className='App'>
            <img src='/bitFlexHeader.png' alt='logo' style={{ width: 500 }} />
            <h4>Coming soon...</h4>
        </div>
    );
}

export default App;
